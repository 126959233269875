export const pAdmin = {
  account: {
    read: 'a.account.read',
    write: 'a.account.write'
  },
  category: {
    read: 'a.category.read',
    write: 'a.category.write',
    update: 'a.category.update',
    delete: 'a.category.delete'
  },

  dashboard: {
    mainCount: 'a.dashboard,mainCount',
    graphDate: 'a.dashboard.graphDate',
    documentSumarry: 'a.dashboard.documentSumarry'
  },

  department: {
    read: 's.department.read',
    write: 's.department.write',
    delete: 's.department.delete'
  },

  metadataKey: {
    read: 'a.metadataKey.read',
    write: 'a.metadataKey.write',
    update: 'a.metadataKey.update',
    delete: 'a.metadataKey.delete'
  },

  metadataKeyTemplate: {
    read: 'a.metadataKeyTemplate.read',
    write: 'a.metadataKeyTemplate.write',
    update: 'a.metadataKeyTemplate.update',
    delete: 'a.metadataKeyTemplate.delete'
  },

  staff: {
    read: 'a.staff.read',
    write: 'a.staff.write',
    resetPwd: 'a.staff.resetPwd',
    setActive: 'a.staff.setActive'
  },

  externalUser: {
    read: 'a.externalUser.read',
    write: 'a.externalUser.write',
    resetPwd: 'a.externalUser.resetPwd',
    setActive: 'a.externalUser.setActive'
  },

  fileDocument: {
    read: 'a.fileDocument.read',
    write: 'a.fileDocument.write',
    update: 'a.fileDocument.update',
    delete: 'a.fileDocument.delete'
  },

  verifyAccount: {
    read: 'a.verifyAccount.read',
    write: 'a.verifyAccount.write'
  },

  requestDocument: {
    read: 'a.requestDocument.read',
    update: 'a.requestDocument.update'
  },

  requestDocumentReport: {
    read: 'a.requestDocumentReport.read'
  },

  documentFileReport: {
    read: 'a.documentFileReport.read'
  }
};
