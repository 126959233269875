import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'form-table',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/form-table/form-table.module').then(m => m.FormTableModule)
      },
      {
        path: 'staff',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'setup',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setup/setup.module').then(m => m.SetupModule)
      },
      {
        path: 'file-document',
        loadChildren: () => import('./routes/file-document/file-document.module').then(m => m.FileDocumentModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./routes/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'school',
        loadChildren: () => import('./routes/school/school.module').then(m => m.SchoolModule)
      },
      {
        path: 'law',
        loadChildren: () => import('./routes/law/law.module').then(m => m.LawModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //{ relativeLinkResolution: 'legacy' }
  exports: [RouterModule]
})
export class AppRoutingModule {}
