<div class="custom-dialog">
    <div class="dialog-header">
        <div class="title">{{title | translate}}</div>
        <mat-icon mat-dialog-close class="btn-close">clear</mat-icon>
    </div>
    <mat-dialog-content>
        <div class="detail-form p-0" autocomplete="off">
            <form id="form" [formGroup]="form">
                <div class="input-group">
                    <div class="grid gap-x-16">
                        <div class="field-reason">
                            <label [ngClass]="{'required': form.get('response_message').invalid}">{{'dialog.reason' | translate}}</label>
                            <mat-form-field appearance="outline">
                                <textarea matInput rows="3" formControlName="response_message" [placeholder]="'dialog.reject_hint' | translate"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close>
            <mat-icon>highlight_off</mat-icon>
            {{'button.cancel' | translate}}
        </button>
        <button form="form" mat-raised-button color="warn" type="submit" (click)="onSubmit()">
            <mat-icon>check_circle_outline</mat-icon>
            {{'button.reject' | translate}}
        </button>
    </mat-dialog-actions>
</div>
