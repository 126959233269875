import { pAdmin } from 'src/app/helpers/permission';
import { MenuItem } from 'src/app/models/menu-item';

export const MENU: MenuItem[] = [
  {
    title: 'menu.dashboard',
    route: '/home',
    icon: '',
    svgIcon: 'dashboard',
    child: [],
    permissions: []
  },
  {
    title: 'menu.file_document',
    route: '/file-document',
    icon: '',
    svgIcon: 'document',
    child: [],
    permissions: [pAdmin.fileDocument.read]
  },
  {
    title: 'menu.laws',
    route: '/law',
    icon: '',
    svgIcon: 'role',
    child: [],
    permissions: []
  },
  {
    title: 'menu.school',
    route: '/school',
    icon: '',
    svgIcon: 'school',
    child: [],
    permissions: []
  },
  {
    title: 'menu.staff.title',
    route: '/staff',
    icon: '',
    svgIcon: 'teamwork',
    child: [
      {
        title: 'menu.staff.creating',
        route: ['/staff/create'],
        icon: '',
        svgIcon: '',
        permissions: []
      },

      {
        title: 'menu.staff.list',
        route: ['/staff/list'],
        icon: '',
        svgIcon: '',
        permissions: []
      }
    ],
    permissions: []
  },
  // {
  //   title: 'menu.report.title',
  //   route: '/report',
  //   icon: '',
  //   svgIcon: 'report',
  //   child: [
  //     {
  //       title: 'menu.report.doc_req',
  //       route: ['/report/doc-req'],
  //       icon: '',
  //       svgIcon: '',
  //       permissions: [pAdmin.requestDocumentReport.read]
  //     },
  //     {
  //       title: 'menu.report.doc_file',
  //       route: ['/report/doc-file'],
  //       icon: '',
  //       svgIcon: '',
  //       permissions: [pAdmin.documentFileReport.read]
  //     }
  //   ],
  //   permissions: []
  // },
  {
    title: 'menu.setup.title',
    route: '/setup',
    icon: '',
    svgIcon: 'settings',
    child: [
      {
        title: 'menu.setup.category',
        route: ['/setup/category'],
        icon: '',
        svgIcon: '',
        permissions: []
      },
      {
        title: 'menu.setup.department',
        route: ['/setup/department'],
        icon: '',
        svgIcon: '',
        permissions: []
      },
      {
        title: 'ប្រភេទច្បាប់',
        route: ['/setup/law-type'],
        icon: '',
        svgIcon: '',
        permissions: []
      },
      {
        title: 'វិស័យ',
        route: ['/setup/sector'],
        icon: '',
        svgIcon: '',
        permissions: []
      }
    ],
    permissions: []
  }
];
